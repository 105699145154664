import * as React from "react";
import { heading } from "./EditCheckoutPayment.module.scss";

export const Heading: React.FC<any> = (props) => {
  return (
    <div id={props.id} className={heading}>
      {props.children}
    </div>
  );
};
