import React, { useState, useEffect } from "react";
import { Wrapper, ListGroupServices, ServiceOptions } from "./AddOnsHelpers";
import { useAppSelector } from "../../../hooks";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";
import Spinner from "react-bootstrap/Spinner";
import IRoom from "../../../@types/IRoom";

const AddOns: React.FC = () => {
  const checkout: any = useAppSelector(
    (state) => state.editReservationCheckout
  );
  const checkoutRooms: Array<IRoom> = checkout.Rooms
    ? Object.values(checkout.Rooms)
    : [];
  const [activeRoomIndex] = useState(checkoutRooms[0] && checkoutRooms[0].id);
  const [loadingServices, setLoadingServices] = useState(true);
  const [servicesFound, setServicesFound] = useState(false);
  const selectedRoom: IRoom = checkoutRooms[0];
  const rateCode = selectedRoom.rate && selectedRoom.rate.RateCode;

  const handleServiceLoad = (servicesFound: any) => {
    setServicesFound(servicesFound);
    setLoadingServices(false);
  };
  useEffect(() => {
    setLoadingServices(true);
  }, [rateCode]);

  return checkoutRooms && checkoutRooms.length > 0 ? (
    <Wrapper className={`${servicesFound ? "d-block" : "d-none"}`}>
      <Row
        className={`align-items-center ${servicesFound && "mb-3"} g-0`}
        id="select-addons"
      >
        <Col xs="auto">
          <h5 className="h6 mb-1 heading" id="services-and-addons">
            {/* <FontAwesomeIcon icon="plus-circle" className="me-2 icon"/> */}
            Services & Add-Ons
          </h5>
        </Col>
      </Row>
      <Row className={`align-items-center ${servicesFound && "mb-3"} g-0`}>
        <Col xs="auto">
          {loadingServices && (
            <Spinner
              id="addons-loader"
              size="sm"
              animation="border"
              className="ms-2"
            />
          )}
        </Col>
      </Row>
      {checkoutRooms.length > 1 ? (
        <ListGroup
          activeKey={activeRoomIndex}
          defaultActiveKey={activeRoomIndex}
          className={`p-0 ${servicesFound && "mb-3"}`}
          variant="flush"
          id="addons-list"
        >
          {checkoutRooms.map((room: any, key: number) => {
            return (
              <ListGroupServices
                key={room.id}
                room={room}
                roomNumber={key + 1}
                expand={key == 0 || activeRoomIndex == room.id}
                onServiceLoad={handleServiceLoad}
              />
            );
          })}
        </ListGroup>
      ) : (
        <ServiceOptions
          room={checkoutRooms[0]}
          onServiceLoad={handleServiceLoad}
        />
      )}
    </Wrapper>
  ) : null;
};

export default AddOns;
