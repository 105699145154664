// extracted by mini-css-extract-plugin
export var active = "EditReservation-module--active--052d8";
export var alt = "EditReservation-module--alt--62154";
export var aside = "EditReservation-module--aside--c21b5";
export var breadcrumb = "EditReservation-module--breadcrumb--166ac";
export var breadcrumbItem = "EditReservation-module--breadcrumbItem--581cb";
export var container = "EditReservation-module--container--a7eb7";
export var counter = "EditReservation-module--counter--ba5c4";
export var crumbLabel = "EditReservation-module--crumbLabel--8317a";
export var darkBackground = "EditReservation-module--darkBackground--964c2";
export var desktopOnly = "EditReservation-module--desktopOnly--633c3";
export var main = "EditReservation-module--main--75336";
export var mobileOnly = "EditReservation-module--mobileOnly--7c9b8";
export var newEditReservationHeader = "EditReservation-module--newEditReservationHeader--ee064";
export var postactive = "EditReservation-module--postactive--55696";
export var styledH3 = "EditReservation-module--styledH3--783fa";
export var textLeft = "EditReservation-module--textLeft--178b5";
export var visuallyHidden = "EditReservation-module--visuallyHidden--1ba98";