import { coreGet } from "./core";

export const getRefreshToken = async (email: string | null, source?: any | null): Promise<any> => {
  let refreshToken = null;

  const externalId = email !== null ? encodeURIComponent(email) : '';
  const apiUrl = `/billing?_format=json&ttl=300&externalID=${externalId}`;

  try {
    const response = await coreGet(apiUrl, source);
    if (response.error) {
      refreshToken = null;
    } else {
      refreshToken = response.refreshToken;
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
  return refreshToken;
};