import * as React from "react";
import Row from "react-bootstrap/Row";

import { styledRow } from "./CurrentTotal.module.scss";

export const StyledRow: React.FC<any> = (props) => {
  return (
    <Row className={`${styledRow} ${props.className} g-0`}>
      {props.children}
    </Row>
  );
};
