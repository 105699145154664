import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import intersection from "lodash/intersection";
import { convertArrayToObject } from "../../../services/helpers";
import { setEditReservationCheckout } from "../../../redux/slices/Checkout/editReservationCheckout";
import { RateList } from "./EditOffersHelpers";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import IRoom from "../../../@types/IRoom";
import { RateListItem } from "../../common/CheckoutOffers/CheckoutOffersHelpers";

const EditOffers: React.FC = () => {
  const dispatch = useAppDispatch();
  const [offers, setOffers] = useState([]);
  const [currentRate, setCurrentRate] = useState(null);
  const checkout = useAppSelector((state) => state.editReservationCheckout);
  const rooms: { [x: string]: IRoom } = checkout.Rooms;
  const [_moveSelectedOfferToTop, setMoveSelectedOfferToTop] = useState(true);
  const [changeOffer, setChangeOffer] = useState("d-none");
  const hasFees = Object?.values(rooms)?.some(
    (room) =>
      room?.room?.BaseRateFees > 1 ||
      (room?.room?.FromRateFeeItems && room?.room?.FromRateFeeItems?.length > 0)
  );
  const perNight =
    Object?.values(rooms)?.length > 1
      ? `avg. per night${hasFees ? " with fees" : ""}`
      : `per night${hasFees ? " with fees" : ""}`;

  useEffect(() => {
    const rateArrays = [];
    let offerCodes = [];
    let selectedPrice = 0;
    const roomsArray = Object.values(rooms) || [];

    const _roomRate = roomsArray.length ? roomsArray[0].rate : {};
    let selectedRate = {
      ..._roomRate,
      Active: true,
    };
    let roomOffers = {};
    roomsArray.forEach(function (room) {
      if (room.room && room.rate) {
        roomOffers = { ...roomOffers, [room.id]: { Rates: {} } };
        const rateArray = [];
        const rateSelected = room.rate;
        if (selectedRate.RateCode === rateSelected.RateCode) {
          selectedRate.Name = rateSelected.Name;
          selectedRate.Description = rateSelected.Description;
          selectedRate.RateCode = rateSelected.RateCode;
          selectedRate.RateType = rateSelected.RateType;
          selectedRate.MultiRates = false;
        } else {
          selectedRate = {
            ...selectedRate,
            ...{
              Name: "Your Price",
              Description: "",
              RateCode: "",
              RateType: "",
              MultiRates: true,
            },
          };
        }

        selectedPrice += parseFloat(rateSelected.RoomRate);
        Object.values(room.room.Rates).forEach(function (rate) {
          // Filter out the currently selected rate.
          // if (isMultiRoom && rateSelected.RateCode !== rate.RateCode) {
          rateArray.push(rate.RateCode);

          // }
        });
        rateArrays.push(rateArray);
      }
    });
    if (rateArrays.length) {
      // Find common rates among rooms.
      offerCodes = intersection(...rateArrays);
    }
    if (offerCodes.length) {
      roomsArray.forEach(function (room) {
        const roomRates = [];
        if (room.room) {
          room.room.Rates.forEach(function (rate) {
            if (offerCodes.includes(rate.RateCode)) {
              roomRates.push(rate);
            }
          });
          roomOffers[room.id].Rates = convertArrayToObject(
            roomRates,
            "RateCode"
          );
        }
      });
    }
    let commonOffers = {};
    const offerCount = Object.values(roomOffers).length;
    Object.values(roomOffers).forEach(function (roomOffer) {
      const offerRates = Object.values(roomOffer.Rates);
      offerRates.forEach(function (offerRate) {
        const match = Object.keys(commonOffers).includes(offerRate.RateCode);
        if (!match) {
          const commonOffer = {
            RateCode: offerRate.RateCode,
            RateType: offerRate.RateType,
            Name: offerRate.Name,
            Description: offerRate.Description,
            RoomRate: parseFloat(offerRate.RoomRate),
            CurrencyCode: offerRate.CurrencyCode,
            CancelPolicy: offerRate.CancelPolicy,
            Guarantee: offerRate.Guarantee,
          };
          commonOffers = {
            ...commonOffers,
            [offerRate.RateCode]: commonOffer,
          };
        } else {
          // Add to the existing rate total.
          commonOffers[offerRate.RateCode].RoomRate += parseFloat(
            offerRate.RoomRate
          );
        }
      });
    });
    const averageOffers = [];
    Object.values(commonOffers).forEach(function (commonOffer) {
      // Convert RoomRate totals to average nightly rate.
      commonOffer.RoomRate = commonOffer.RoomRate / offerCount;
      averageOffers.push(commonOffer);
    });
    selectedRate.RoomRate = selectedPrice / roomsArray.length;
    // selectedRate.Active = true;
    setCurrentRate(selectedRate);

    // averageOffers.push(selectedRate);
    averageOffers.sort((f, s) => {
      return f.RoomRate - s.RoomRate;
    });

    // move selected offer to top
    // if(moveSelectedOfferToTop) {
    //   const selectedOfferIndex = averageOffers.findIndex(averageOffers => averageOffers.RateCode == selectedRate.RateCode);
    //   selectedOfferIndex != -1 && averageOffers.push(...averageOffers.splice(0, selectedOfferIndex));
    // }

    setOffers(averageOffers);
  }, [rooms]);

  const handleSelectRate = (event: React.MouseEvent<HTMLElement>) => {
    setMoveSelectedOfferToTop(false);
    const rateCode = event.target.value;
    const selectedOffer = offers.filter((o) => o.RateCode == rateCode)[0];
    setCurrentRate(selectedOffer);
    let currentRooms = { ...rooms };
    Object.values(currentRooms).forEach(function (room: IRoom) {
      const _rate = room.room.Rates.filter(
        (roomRate) => roomRate.RateCode === rateCode
      )[0];
      const _services = null;
      currentRooms = {
        ...currentRooms,
        [room.id]: { ...room, rate: _rate, services: _services },
      };
      //   currentRooms[room.id].rate = room.room.Rates.filter(roomRate => roomRate.RateCode === rateCode)[0];
      //   currentRooms[room.id].services = null;
    });
    const newCheckout = { ...checkout, Rooms: currentRooms };
    dispatch(setEditReservationCheckout(newCheckout));
  };

  const handleChange = () => {
    const updatedValue = changeOffer == "d-none" ? "d-block" : "d-none";
    setChangeOffer(updatedValue);
  };

  return (
    <div className="bg-light ps-2 pe-2 ps-lg-3 pe-lg-3 rounded">
      <Row className="align-items-center mb-3 g-0">
        <Col xs="auto">
          <h5 className="h6 mb-0">
            <FontAwesomeIcon icon={"tag"} className="me-2" />
            Select a Rate
          </h5>
        </Col>
        <Col className="text-end">
          <Button
            className="text-uppercase"
            onClick={handleChange}
            variant="link"
          >
            {changeOffer == "d-none" ? "CHANGE" : "HIDE"}
          </Button>
        </Col>
      </Row>

      <RateList show={changeOffer}>
        {offers.length > 0 ? (
          <>
            {currentRate?.MultiRates && (
              <RateListItem
                rate={currentRate}
                currentRate={currentRate}
                handleSelectRate={handleSelectRate}
                perNight={perNight}
                crsCode={checkout.HotelCode}
              />
            )}
            {offers.map((rate, i) => {
              return (
                <RateListItem
                  key={`rl${i}-${rate.RateCode}`}
                  rate={rate}
                  currentRate={currentRate}
                  handleSelectRate={handleSelectRate}
                  perNight={perNight}
                  crsCode={checkout.HotelCode}
                />
              );
            })}
          </>
        ) : (
          <div>No offers available</div>
        )}
      </RateList>
    </div>
  );
};
export default EditOffers;
