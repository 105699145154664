import React, { useEffect } from "react";
import { IMyCheckProps } from "./MyCheckProps";
import { Container } from "./MyCheckHelpers";
import { useScript } from "../../../hooks/useScript";
import { getRefreshToken } from "../../../services/mycheck";

const MyCheck = (props: IMyCheckProps): JSX.Element => {
    const myCheckScript = process.env.GATSBY_MyCheckScript;
    const [loaded, error] = useScript(myCheckScript);
    const isLoggedIn = false;
    const email = '';

      useEffect(() => {
        (async () => {
          if (loaded && !error) {
            const refreshToken = await getRefreshToken(email);
            await initializeMycheck(refreshToken);
          }
        })();
        return () => {
            if (typeof window !== 'undefined') {
                if ((window as any).mycheckWallet !== undefined) {
                    (window as any).mycheckWallet.destroy();
                }
            }
        };
      }, [isLoggedIn, loaded]);

      const handleWalletReady = () => {
        if (typeof window !== 'undefined') {
            (window as any).mycheckWallet.setGiftCardsInfo(false);
        }
        props.onWalletReady && props.onWalletReady();
      };

      const handleWalletInit = () => {
        props.onWalletInit && props.onWalletInit();
      };

      // const handleGiftCardChange = (details: any) => {
      //   props.onGiftCardChange && props.onGiftCardChange(details);
      // };

      const initializeMycheck = (refreshToken: any) => {
        if ((typeof window !== 'undefined') && (undefined !== (window as any).mycheckWallet)) {
            (window as any).mycheckWallet.init(props.walletId, {
            //  template : 'checkoutScreen',
            // manageElement: '#myc-wallet-modal',
            publishableKey: process.env.GATSBY_MYCHECK_KEY,
            refreshToken: refreshToken,
            acceptedCards: ['visa', 'mastercard', 'amex', 'diners', 'discover', 'jcb', 'maestro'],
            local: "en",
            acceptedAlternativeWallets: [],
            events: {
              onInit: handleWalletInit,
              onReady: handleWalletReady,
              // eslint-disable-next-line no-console
              userData: (data: any) => console.info('user data is:', data),
              // afterSelectCreditCard: handleAfterSelectCreditCard

            },
            custom: {
              creditCardFormPlaceholders: {
                CREDIT_CARD_NO: '6457 1008 2986 1400',
                CVV: '123',
                ZIP: '12345'
              },
              giftCardsInfo: false
            },
          });
        }
      };

      return (
        <Container className={`${props.className}`}>
          {props.children}
        </Container>
      );
};

export default MyCheck;
