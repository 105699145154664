import * as React from "react";
import { styledRow } from "./UpdatedTotal.module.scss";
import Row from "react-bootstrap/Row";

export const StyledRow: React.FC<any> = (props) => {
  return (
    <Row className={`${styledRow} ${props.className || ""} g-0`}>
      {props.children}
    </Row>
  );
};
