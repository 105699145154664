import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useSelector, useDispatch } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import RoomCard from "../../common/RoomCard/RoomCard";
import { setEditReservationCheckout } from "../../../redux/slices/Checkout/editReservationCheckout";
import { useRoomsRates } from "../../../hooks/useRoomsRates";
import { ISelectRoomProps } from "./SelectRoomProps";
import { useRoomDetail } from "../../../hooks/useRoomDetail";
import { convertArrayToObject } from "../../../utils/helpers";
import merge from "lodash/merge";
import { sortRooms } from "../../../services/helpers";
import NotAvailableMessage from "../../common/NotAvailableMessage/NotAvailableMessage";
import { setCheckout } from "../../../redux/slices/Checkout/checkout";

const SelectRoom: React.FC<ISelectRoomProps> = (props) => {
  const onRoomsLoad = props.onRoomsLoad;
  const dispatch = useDispatch();
  const checkout = useSelector((state: any) => state.editReservationCheckout);
  const index = Object.keys(checkout.Rooms)[0];
  const [loadingRates, rooms, roomRates, queryHotel] = useRoomsRates(
    index,
    checkout
  );

  const [_loadingRoomDetail, _roomDetail, allRoomsWithoutRates] = useRoomDetail(
    {
      hotelCode: checkout.HotelCode,
      crsName: checkout.Crs,
    }
  );
  const [roomRatesUpdatedAvailability, setRoomRatesUpdatedAvailability] =
    useState(roomRates);

  const checkoutRoomsArray = Object.values(checkout.Rooms);
  const [roomsToDisplay, setRoomsToDisplay] = useState(null);
  const [showNoRoomsmessage, setShowNoRoomsmessage] = useState(false);

  useEffect(() => {
    setShowNoRoomsmessage(false);
    if (rooms) {
      onRoomsLoad && onRoomsLoad(roomRates);
      // update rooms availability
      roomRates &&
        (roomRates as any[]).forEach((r: any) => {
          const selectedRooms = checkoutRoomsArray.filter((cRoom: any) => {
            return cRoom.room && cRoom.room.RoomCode == r.RoomCode;
          });
          r.Availability = r.Availability - selectedRooms.length;
        });
      setRoomRatesUpdatedAvailability(roomRates);
    } else {
      setRoomRatesUpdatedAvailability(null);
      setShowNoRoomsmessage(true);
    }
  }, [rooms, roomRates]);

  useEffect(() => {
    if (allRoomsWithoutRates) {
      if (roomRatesUpdatedAvailability) {
        const ratesObject = convertArrayToObject(
          roomRatesUpdatedAvailability,
          "RoomCode"
        );
        merge(ratesObject, allRoomsWithoutRates);
        const mergedRoomsArray = Object.values(ratesObject);
        const sortedRooms = sortRooms(mergedRoomsArray); //mergedRoomsArray.sort(r => r.Availability > 0 && -1 );
        setRoomsToDisplay(sortedRooms);
      }
    }
  }, [allRoomsWithoutRates, roomRatesUpdatedAvailability]);

  const handleSelectRoom = async (event: any) => {
    await dispatch(
      setCheckout({
        ...checkout,
        hotelLocation: queryHotel?.hotelLocation,
        Brand: queryHotel?.brand.code,
        redemptionItem: queryHotel?.redemptionItem,
      })
    );
    const dataset = event.target.dataset;
    const roomCode = dataset.room;
    const room = (roomRatesUpdatedAvailability as any[]).filter(
      (roomRate: any) => roomRate.RoomCode === roomCode
    )[0];
    const rateCode = room.FromRateCode;
    const rate = room.Rates.filter(
      (rateObject: any) => rateObject.RateCode === rateCode
    )[0];
    let currentRoom = checkout.Rooms[index];
    currentRoom = { ...currentRoom, rate: rate, room: room, services: null };
    // // dispatch(updateReservationRoom(currentRoom));
    dispatch(
      setEditReservationCheckout({
        ...checkout,
        Rooms: { ...checkout.Rooms, [index]: currentRoom },
        Step: "finish",
      })
    );
  };

  // const availableRooms = roomRatesUpdatedAvailability && (roomRatesUpdatedAvailability as any[]).filter((room: any) => room.Availability > 0);

  const getRoomsCard = () => {
    if (roomsToDisplay && roomsToDisplay.length > 0) {
      return roomsToDisplay.map((room: any) => {
        return (
          <Col key={room.RoomCode} className="mb-4">
            <RoomCard room={room} selectRoom={handleSelectRoom} />
          </Col>
        );
      });
    }
  };

  if (loadingRates) {
    return (
      <div className="text-center">
        <Spinner animation="border" />
      </div>
    );
  } else if (roomsToDisplay) {
    return (
      <React.Fragment>
        {/* <UnlockBanner /> */}
        <Row lg={3} xs={1}>
          {getRoomsCard()}
        </Row>
      </React.Fragment>
    );
  }
  return (
    <Row>
      <Col>{showNoRoomsmessage && <NotAvailableMessage />}</Col>
    </Row>
  );
};

export default SelectRoom;
