import React, { useState, useEffect } from "react";
import { IUpdatedTotalProps } from "./UpdatedTotalProps";
import { StyledRow } from "./UpdatedTotalHelpers";
import { useAppSelector, useAppDispatch } from "../../../hooks";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import moment from "moment-mini";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { priceFormatter } from "../../../utils/helpers";
import { formatDate, parseSearchDate } from "../../../services/dates";
import { addReservationEditCheckoutTotal } from "../../../redux/slices/Checkout/editCheckoutTotal";
import { ITaxItem } from "../../../@types/ITaxItem";

const UpdatedTotal: React.FC<IUpdatedTotalProps> = (props) => {
  const dispatch = useAppDispatch();
  const checkoutObj = useAppSelector((state) => state.editReservationCheckout);
  const rooms = Object.values(checkoutObj.Rooms) || [];
  const checkInDate = checkoutObj.Start;
  const checkOutDate = checkoutObj.End;
  const stayDates = `${formatDate(
    parseSearchDate(checkInDate),
    "MMM D"
  )} - ${formatDate(parseSearchDate(checkOutDate), "MMM D, YYYY")}`;
  const payment = checkoutObj.Payment || {};
  const applyBucks = !!payment.with_giftcards;
  const helloBucksAmount: number = payment.giftcard_amount || 0;

  let taxesAndFeesTotal = 0;
  let total = 0;
  let subTotal = 0;
  const nightsInDays: number = moment(checkOutDate, "YYYY-MM-DD").diff(
    moment(checkInDate, "YYYY-MM-DD"),
    "days"
  );
  const nights = nightsInDays > 1 ? `${nightsInDays} Nights` : `1 Night`;
  let bucksApplied = 0;
  let remainingBucks: number = parseFloat(helloBucksAmount);
  const currency =
    rooms && rooms.length && rooms[0].rate ? rooms[0].rate.CurrencyCode : "";

  const taxItemsWise: Array<ITaxItem> = [];
  rooms.forEach(function (room: any) {
    const rate = room.rate;
    const services = room.services;
    if (rate) {
      if (applyBucks && remainingBucks > 0) {
        const subTotal = parseFloat(rate.Subtotal);
        if (remainingBucks <= subTotal) {
          bucksApplied += remainingBucks;
          remainingBucks = 0;
        } else {
          bucksApplied += subTotal;
          remainingBucks = remainingBucks - subTotal;
        }
      }
      let taxesAndFees = 0;
      rate.TaxItems &&
        rate.TaxItems.forEach((taxItem: ITaxItem) => {
          let taxItemAmount = parseFloat(taxItem.Amount);
          if (taxItem.ChargeFrequency == 1) {
            // per night
            const numOfNights =
              moment(taxItem.ExpireDate).diff(
                moment(taxItem.EffectiveDate),
                "days"
              ) + 1;
            taxItemAmount = taxItemAmount * numOfNights;
          }
          if (taxItemAmount) {
            taxesAndFees += taxItemAmount;
          }

          const existingItemindex = taxItemsWise.findIndex(
            (item) => item.Name === taxItem.Name
          );
          if (existingItemindex > -1) {
            taxItemsWise[existingItemindex]["Amount"] =
              taxItemsWise[existingItemindex]["Amount"] + taxItemAmount;
          } else {
            taxItemsWise.push({ ...taxItem, Amount: taxItemAmount });
          }
        });
      taxesAndFeesTotal += taxesAndFees;
      subTotal += parseFloat(rate.Subtotal);
    }
    if (services) {
      Object.values(services).forEach((service: any) => {
        const serviceCount = parseInt(service.AvailedQuantity);
        taxesAndFeesTotal += parseFloat(service.Tax) * serviceCount;
        subTotal += parseFloat(service.Price) * serviceCount;
        // total = subTotal + taxesAndFeesTotal;
      });
    }
    total = parseFloat(subTotal + taxesAndFeesTotal) - bucksApplied;
  });

  //setTotalAmount(total);
  const [showPriceDetails, setShowPriceDetails] = useState(false);
  const [showTaxDetails, setShowTaxDetails] = useState(false);

  const handleShowPriceDetails = () => {
    setShowPriceDetails(!showPriceDetails);
  };

  const handleShowTaxDetails = () => {
    setShowTaxDetails(!showTaxDetails);
  };

  const detailsIcon = !showPriceDetails ? "angle-down" : "angle-up";
  const taxDetailsIcon = !showTaxDetails ? "angle-down" : "angle-up";

  const getRoomServiceSubTotal = (room: any) => {
    const services = room.services;
    let serviceSubTotal = 0;
    if (services) {
      Object.values(services).forEach((service: any) => {
        const serviceCount = parseInt(service.AvailedQuantity);
        serviceSubTotal += parseFloat(service.Price) * serviceCount;
      });
    }
    return serviceSubTotal;
  };

  useEffect(() => {
    dispatch(addReservationEditCheckoutTotal(total));
  }, [rooms, total]);

  return (
    <Container className={props.className}>
      <StyledRow className="pt-3">
        <Col className="details px-0">
          <div className="heading">
            {props.Heading ? props.Heading : "Updated Total"}
          </div>
        </Col>
        {showPriceDetails ? (
          <Col className="text-end total pe-0">
            {priceFormatter(total, 2, currency)}
          </Col>
        ) : (
          <>
            <Col xs="auto" className="text-end breakdown mb-2 mb-lg-0 me-lg-4">
              <div>
                {nights} {rooms.length > 1 ? `(${rooms.length} Rooms)` : ""}:{" "}
                {priceFormatter(subTotal, 2, currency)}
              </div>
              <div>
                Taxes & Fees: {priceFormatter(taxesAndFeesTotal, 2, currency)}
              </div>
            </Col>
            <Col className="text-end total" lg="auto" id="cartTotal">
              {priceFormatter(total, 2, currency)}
            </Col>
          </>
        )}
      </StyledRow>
      {showPriceDetails && (
        <StyledRow className={`pb-3 pb-lg-0`}>
          <Col className="px-0 d-flex align-items-end">
            <div className="breakdown mt-1 w-100">
              <div className="mb-n3 mb-lg-0">
                <div className="fw-bold my-2">
                  {stayDates} ({nights})
                </div>
                {rooms.map((room: any, idx: number) => {
                  if (room.rate) {
                    const servicePrice = getRoomServiceSubTotal(room);
                    return (
                      <div key={`room-price${idx}`}>
                        <div className="fw-bold mt-2">Room {idx + 1}</div>
                        <Row>
                          <Col>{nights}</Col>
                          <Col>
                            {priceFormatter(room.rate.Subtotal, 2, currency)}
                          </Col>
                        </Row>
                        {servicePrice > 0 && (
                          <Row>
                            <Col>Options</Col>
                            <Col>
                              {priceFormatter(servicePrice, 2, currency)}
                            </Col>
                          </Row>
                        )}
                      </div>
                    );
                  }
                  return null;
                })}
                <div className="my-3">
                  <Button
                    variant="link"
                    className="mb-1 p-0"
                    onClick={handleShowTaxDetails}
                  >
                    <span className="fw-bold">
                      Taxes &amp; Fees:{" "}
                      {priceFormatter(taxesAndFeesTotal, 2, currency)}
                    </span>
                    <FontAwesomeIcon icon={taxDetailsIcon} className="ms-1" />
                  </Button>
                  {showTaxDetails &&
                    taxItemsWise.map((tItem, idx) => {
                      if (tItem.Amount) {
                        return (
                          <div key={`tax-item${idx}`}>
                            <Row>
                              <Col>{tItem.Name}</Col>
                              <Col>
                                {priceFormatter(
                                  parseFloat(tItem.Amount),
                                  2,
                                  currency
                                )}
                              </Col>
                            </Row>
                          </div>
                        );
                      }
                      return null;
                    })}
                </div>
                {applyBucks && (
                  <div className="my-3">
                    Hello Bucks: -{priceFormatter(bucksApplied)}
                  </div>
                )}
                <div className="grand-total mb-3">
                  Grand Total: {priceFormatter(total, 2, currency)}
                </div>
              </div>
            </div>
          </Col>
        </StyledRow>
      )}
      <StyledRow className={props.Heading ? "" : "border-bottom pb-2"}>
        <Col className="p-0">
          <Button
            variant="link"
            className="mt-2 p-0 text-uppercase price-btn"
            onClick={handleShowPriceDetails}
          >
            {!showPriceDetails ? "Expand Price Details" : "HIDE Price Details"}
            <FontAwesomeIcon icon={detailsIcon} className="ms-1" />
          </Button>
        </Col>
      </StyledRow>
    </Container>
  );
};

export default UpdatedTotal;
