import React, { useState, useEffect } from "react";
import { IEditCheckoutPaymentProps } from "./EditCheckoutPaymentProps";
import { Heading } from "./EditCheckoutPaymentHelpers";
import { setEditReservationCheckout } from "../../../redux/slices/Checkout/editReservationCheckout";
import MyCheck from "../../common/MyCheck/MyCheck";
import { useAppSelector, useAppDispatch } from "../../../hooks";

const EditCheckoutPayment: React.FC<IEditCheckoutPaymentProps> = (props) => {
  const dispatch = useAppDispatch();
  const profileModal = useAppSelector(
    (state) => state.member && state.member.profileModal
  );
  const checkout: any = useAppSelector(
    (state) => state.editReservationCheckout
  );
  const totalAmount = useAppSelector(
    (state) => state.editReservationCheckoutTotal
  );

  const [payment, setPayment] = useState(checkout.Payment);

  const [_giftCard, setGiftCard] = useState(null);
  const [_paymentReady, setPaymentReady] = useState(false);
  const [myCheckReady, setMyCheckReady] = useState(false);

  const handleSetMycheckReady = () => {
    // window.mycheckWallet.setGiftCardsInfo(false);
    setMyCheckReady(true);
    props.handleSetScroll(true);
  };

  const handleGiftCardChange = (details: any) => {
    const withGiftCard = details.isSelected && details.amount > 0 ? 1 : 0;
    setGiftCard(details);
    setPayment({
      ...payment,
      with_giftcards: withGiftCard,
      giftcard_amount: parseFloat(details.amount).toFixed(2),
    });
  };

  const handleSelectCreditCard = async (token: string) => {
    setPayment({ ...payment, ccToken: token });
    setPaymentReady(true);
  };

  useEffect(() => {
    if (myCheckReady) {
      const defaultPayment = {
        amount: totalAmount,
        ccToken: null,
        currency: "USD",
        method: "CHARGE",
        with_giftcards: 0,
        giftcard_amount: 0,
      };
      setPayment(defaultPayment);
    }
  }, [myCheckReady]);

  useEffect(() => {
    if (myCheckReady) {
      setPayment({ ...payment, amount: totalAmount });
    }
  }, [totalAmount]);

  useEffect(() => {
    myCheckReady &&
      payment &&
      dispatch(
        setEditReservationCheckout({
          ...checkout,
          Payment: { ...checkout.Payment, ...payment },
        })
      );
  }, [payment]);

  const SectionHeading = () => {
    return <Heading id={"payment-details"}>Payment Details</Heading>;
  };
  //onWalletInit, onWalletReady, afterSelectCreditCard, onGiftCardChange
  return !profileModal ? (
    <MyCheck
      className={props.className}
      afterSelectCreditCard={handleSelectCreditCard}
      onGiftCardChange={handleGiftCardChange}
      onWalletReady={handleSetMycheckReady}
      email={props.email}
      walletId="myc-wallet-checkout"
    >
      <SectionHeading />
      <section>
        <div id="myc-wallet-checkout" />
      </section>
    </MyCheck>
  ) : null;
};
export default EditCheckoutPayment;
