import * as React from "react";
import { rateList } from "./EditOffers.module.scss";
import ListGroup from "react-bootstrap/ListGroup";

export const RateList: React.FC<any> = (props) => {
  return (
    <ListGroup className={`${rateList} pb-2 ${props.show}`}>
      {props.children}
    </ListGroup>
  );
};
