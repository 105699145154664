import React, { useState } from "react";
import moment from "moment-mini";
import { IReservationDetailsProps } from "./ReservationDetailsProps";
import { StyledContainer } from "./ReservationDetailsHelpers";
import { StaticImage } from "gatsby-plugin-image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { setEditReservationCheckout } from "../../../redux/slices/Checkout/editReservationCheckout";
import { resDisplayDateFormat } from "../../../services/dates";

const ReservationDetails: React.FC<IReservationDetailsProps> = ({
  reservation,
  room,
}) => {
  const dispatch = useAppDispatch();
  const checkout: { [x: string | number]: any } = useAppSelector(
    (state) => state.editReservationCheckout
  );
  const roomImage = room?.Image;
  const adults = reservation.Count.Adult || 1;
  const children = reservation.Count.Children || 0;
  const [isLoadingDetail, _setIsloadingDetail] = useState(false);

  let guests = adults > 1 ? `${adults} Adults` : "1 Adult";
  if (children >= 1) {
    const childrenStr = children > 1 ? `${children} Children` : "1 Child";
    guests += `, ${childrenStr}`;
  }

  const handleChangeRoom = () => {
    const roomId = Object.keys(checkout.Rooms)[0];
    let currentRoom = checkout.Rooms[roomId];
    currentRoom = { ...currentRoom, rate: null, room: null, services: null };
    dispatch(
      setEditReservationCheckout({
        ...checkout,
        Rooms: { ...checkout.Rooms, [roomId]: currentRoom },
        Step: "select_room",
      })
    );
  };

  const stayDateStr = (() => {
    if (reservation.Start) {
      const arrival = moment(reservation.Start, "YYYY-M-D");
      const departure = moment(reservation.End, "YYYY-M-D");
      const numberNights = departure.diff(arrival, "days");

      const nightStr = numberNights > 1 ? " Nights" : " Night";
      const displayArrival = arrival.format(resDisplayDateFormat);
      const displayDeparture = departure.format(resDisplayDateFormat);
      return `${displayArrival} - ${displayDeparture} (${numberNights}${nightStr})`;
    } else {
      return "";
    }
  })();

  return (
    <StyledContainer>
      <Row className="image-hotel-wrapper g-0">
        <Col sm={12} md={6} className="image-wrapper">
          {roomImage ? (
            <img src={roomImage} alt={room.Name} />
          ) : (
            <StaticImage
              src="../../../images/hotel_fallback.jpg"
              alt={room.Name}
              placeholder="blurred"
              objectFit="cover"
            />
          )}
        </Col>
        <Col className="h-100 bg-light">
          <Row className="h-100 px-md-4 ps-2 pt-3  pt-lg-5 res-detail g-0">
            <Col>
              {isLoadingDetail ? (
                <Spinner animation="grow" size="sm" />
              ) : (
                <>
                  <div className=" d-flex flex-row align-items-start">
                    <FontAwesomeIcon icon="bed" className="me-1" />
                    <div className="flex-grow-11 ">{room.Name}</div>
                    <Button
                      variant="link"
                      onClick={handleChangeRoom}
                      className="text-uppercase ps-2 py-0"
                    >
                      Change
                    </Button>
                  </div>
                </>
              )}
              <div>
                <FontAwesomeIcon icon={faCalendarAlt} className="me-2 mt-1 " />
                <span>
                  <u>{stayDateStr}</u>
                </span>
              </div>
              <div>
                <FontAwesomeIcon icon="user-friends" className="me-2 mt-2" />
                {guests}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </StyledContainer>
  );
};

export default ReservationDetails;
