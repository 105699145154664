import * as React from "react";
import Container from "react-bootstrap/Container";
import {
  container,
  darkBackground,
  styledH3,
  breadcrumb,
  breadcrumbItem,
  counter,
  active,
  postactive,
  crumbLabel,
  newEditReservationHeader,
} from "./EditReservation.module.scss";
import { Col, Row } from "react-bootstrap";
import SubTitle from "../../components/global/SubTitle/SubTitle";
import { navigate } from "gatsby";
import { slugify } from "transliteration";

export const StyledContainer: React.FC<any> = (props) => {
  return (
    <Container className={`${container} pt-5 pb-5`} fluid="sm">
      {props.children}
    </Container>
  );
};

export const DarkBackground: React.FC<any> = (props) => {
  return (
    <div
      className={darkBackground}
      style={{ display: props.show ? "block" : "none" }}
    >
      {props.children}
    </div>
  );
};

export const Title3: React.FC<any> = (props) => {
  return (
    <h3 id={props.id} className={styledH3}>
      {props.children}
    </h3>
  );
};

const crumbs = [
  {
    text: "Select Room",
    id: "select-room",
  },
  {
    text: "Select Rate",
    id: "select-rate",
  },
  {
    text: "Payment & Review",
    id: "payment-review",
  },
];

const EditReservationBreadCrumb = (props: any) => {
  const { step } = props;
  const { crumbs } = props;
  let stepId = 0;
  if (step == "select-room") {
    stepId = 0;
  } else if (step == "select-rate") {
    stepId = 1;
  } else {
    stepId = 2;
  }

  const crumbItems = crumbs.map((crumb: any, idx: number) => {
    let itemClass = "";
    let ariaCurrent = "";
    if (idx == stepId) {
      itemClass = active;
      ariaCurrent = "page";
    } else if (idx > stepId) {
      itemClass = postactive;
    }

    const handleStepNavigation = (stepValue: string, itemClass: any) => {
      const active =
        itemClass?.includes("active") || itemClass?.includes("postactive");
      if (stepValue == "select-room" && !active) {
        navigate("/edit-reservation/select-room");
      } else if (stepValue == "select-rate" && !active) {
        navigate("/edit-reservation/select-rate");
      } else if (stepValue == "payment-review" && !active) {
        navigate("/edit-reservation/select-payment");
      }
    };

    return (
      <BreadCrumbItem
        key={`customCrumb-${idx}`}
        index={idx}
        itemClass={itemClass}
        crumb={crumb}
        ariaCurrent={ariaCurrent}
        handleStepNavigation={handleStepNavigation}
        crumbId={crumb.id}
      />
    );
  });
  return step ? (
    <nav aria-label="breadcrumb">
      <Row>
        <ol className={breadcrumb}>{crumbItems}</ol>
      </Row>
    </nav>
  ) : null;
};

const BreadCrumbItem = (props: any) => {
  const {
    itemClass,
    crumb,
    index,
    ariaCurrent,
    handleStepNavigation,
    crumbId,
  } = props;
  return (
    <Col lg={2} sm={4}>
      <li
        id={slugify(crumb.text)}
        className={`${itemClass} ${breadcrumbItem}`}
        aria-current={ariaCurrent}
        onClick={() => {
          handleStepNavigation(crumbId, itemClass);
        }}
      >
        <span className={counter}>{index + 1}</span>
        <span className={crumbLabel}>{crumb.text}</span>
      </li>
    </Col>
  );
};

export const EditReservationHeader: React.FC<any> = (props) => {
  let title = "Select Your Room";
  if (props.step == "select-room") {
    title = "Select Your Room";
  } else if (props.step == "select-rate") {
    title = "Select a Rate";
  } else if (props.step == "payment-review") {
    title = "Payment & Review";
  }
  return (
    <Row className={newEditReservationHeader}>
      <Col>
        <div>
          <SubTitle>Edit Reservation</SubTitle>
          <Title3 id={slugify(title)}>{title}</Title3>
          <hr style={{ width: "1px solid #D3D3D3" }} />
          <EditReservationBreadCrumb
            crumbs={crumbs}
            step={props.step}
            location={props.location}
          />
        </div>
      </Col>
    </Row>
  );
};
