import React, { useState, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../../hooks";
import { IOptionsItemProps } from "./OptionsItemProps";
import AddOnsIncrementor from "../../common/AddOnsIncrementor/AddOnsIncrementor";
import { StyledRow, Tooltip, resolvePriceType } from "./OptionsItemHelpers";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tippy from "@tippyjs/react";
import FormCheck from "react-bootstrap/FormCheck";
import { updateReservationRoom } from "../../../redux/slices/Checkout/editReservationCheckout";
import { _getStaticImage } from "gatsby-plugin-image/dist/src/components/static-image.server";

const OptionsItem: React.FC<IOptionsItemProps> = ({
  service,
  nights,
  occupancy,
  room,
  id,
}) => {
  const dispatch = useAppDispatch();
  const checkout = useAppSelector((state) => state.editReservationCheckout);
  const checkoutRooms = checkout.Rooms;
  const inventoryCode = service.ServiceInventoryCode.trim();

  const priceTypeFromApi = service.ServicePricingType.toString()
    .toLowerCase()
    .trim()
    .replace(/\s/g, "_");
  const priceTypes = resolvePriceType(priceTypeFromApi);

  const priceInteger = Math.ceil(service.Price.Rate);
  const free = priceInteger === 0;
  const price = !free ? `+ $${service.Price.Rate}` : "Free";
  const calculatedPrice =
    priceTypes.indexOf("per_night") !== -1
      ? service.Price.Rate * nights
      : service.Price.Rate;
  const max =
    priceTypeFromApi === "per_person_per_night"
      ? service.Quantity
      : nights * occupancy;
  const itemSelected = free || (room.services && room.services[inventoryCode]);

  const [count, setCount] = useState(
    (itemSelected &&
      room.services &&
      room.services[inventoryCode] &&
      room.services[inventoryCode].AvailedQuantity) ||
      1
  );

  const handleChangeCount = (index: any, count: any) => {
    setCount(count);
  };

  const handleChangeAddOns = (e: any) => {
    const ele = e.target;
    const checked = ele.checked;
    const inventoryCode = ele.value;
    const data = ele.dataset;
    const isFree = data.free != "false";
    if (isFree) {
      e.preventDefault();
      return false;
    }
    let updatedServices: any = {};
    if (checked) {
      updatedServices = {
        ...room.services,
        ...{
          [service.ServiceInventoryCode]: {
            Description: service.Description,
            ServiceRPH: service.ServiceRPH,
            ServiceInventoryCode: service.ServiceInventoryCode,
            ServicePricingType: service.ServicePricingType,
            Price: parseFloat(data.price),
            Tax: parseFloat(data.tax),
            AvailedQuantity: count,
            Title: service?.Title,
          },
        },
      };
    } else {
      if (room["services"][inventoryCode]) {
        const { [inventoryCode]: deletedService, ...rest } = room["services"];

        updatedServices = rest;
      }
    }
    dispatch(updateReservationRoom({ ...room, services: updatedServices }));
  };

  useEffect(() => {
    if (room.services && room.services[inventoryCode]) {
      const roomService = room.services[inventoryCode];
      // updatedRoomService.AvailedQuantity = count;

      const updatedRoomServices = {
        ...room.services,
        [inventoryCode]: { ...roomService, AvailedQuantity: count },
      };
      dispatch(
        updateReservationRoom({ ...room, services: updatedRoomServices })
      );
    }
  }, [count]);

  return (
    <StyledRow id={id}>
      <Col xs="6" lg="8">
        <FormCheck>
          <FormCheck.Input
            type="checkbox"
            defaultChecked={itemSelected}
            name={service.Title}
            value={inventoryCode}
            onClick={handleChangeAddOns}
            data-free={free}
            data-price={calculatedPrice}
            data-tax={service.Price.Taxes}
            id="sevice-checkbox-input"
          />
          <FormCheck.Label id={`service-title`}>
            {service.Title}
          </FormCheck.Label>
          {service.Description && (
            <Tippy content={service.Description} theme="bootstrap">
              <Tooltip id={`service-description`}>
                <FontAwesomeIcon icon={"info-circle"} className="icon" />
              </Tooltip>
            </Tippy>
          )}
        </FormCheck>
      </Col>
      <Col xs="3" lg="2">
        {priceTypes.indexOf("per_item") != -1 ? (
          <AddOnsIncrementor
            name={inventoryCode.toLowerCase()}
            value={itemSelected ? count : 1}
            onChangeCount={handleChangeCount}
            min={1}
            max={itemSelected ? max : 1}
          />
        ) : (
          ""
        )}
      </Col>
      <Col id="offer-price" className="price">
        {price}
      </Col>
    </StyledRow>
  );
};

export default OptionsItem;
